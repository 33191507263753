import ReactHlsPlayer from 'react-hls-player';
import { useRef, useEffect } from 'react';

function CaptiveVideo({ videoURL }) {
    const playerRef = useRef();

    useEffect(() => {
      function fireOnVideoStart() {
        // Do some stuff when the video starts/resumes playing
        console.log("play")
      }
  
      playerRef.current.addEventListener('play', fireOnVideoStart);
  
      return playerRef.current.removeEventListener('play', fireOnVideoStart);
    }, []);

    useEffect(() => {
        function fireOnVideoEnd() {
          // Do some stuff when the video ends
          console.log("done")
        }
    
        playerRef.current.addEventListener('ended', fireOnVideoEnd);
    
        return playerRef.current.removeEventListener('ended', fireOnVideoEnd);
      }, []);

    return (
            <ReactHlsPlayer
            playerRef={playerRef}
            autoPlay={true}
            // controls={true}
            width="100%"
            src={videoURL}
            hlsConfig={{
            maxLoadingDelay: 4,
            minAutoBitrate: 0,
            lowLatencyMode: true,
            }}
        />
    )
}

export default CaptiveVideo