import CaptiveVideo from "../components/CaptiveVideo"
import { VideoArgs } from "../types"

function VideoPage({ videoURL }: VideoArgs) {

    return (
        <div>
            <CaptiveVideo videoURL={videoURL} />
        </div>
    )
}

export default VideoPage