import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Question } from '../types'
import HLS from '../video/HLS'
import RangeSlider from 'react-range-slider-input';
import './MainPage.css'

interface PregameInput {
    postReset?: boolean,
    postGame?: boolean,
    defaultIndex?: number
}

enum MoodIndex {
    Great = 4,
    Good = 3,
    Okay = 2,
    Bad = 1,
    Awful = 0,
}

enum Mood {
    Great = "Great",
    Good = "Good",
    Okay = "Okay",
    Bad = "Kinda bad",
    Awful = "Awful",
}


const toMoodIndex = (mood: Mood): MoodIndex => {
    switch (mood) {
        case Mood.Great:
            return MoodIndex.Great;
        case Mood.Good:
            return MoodIndex.Good;
        case Mood.Okay:
            return MoodIndex.Okay;
        case Mood.Bad:
            return MoodIndex.Bad;
        case Mood.Awful:
            return MoodIndex.Awful;
        default:
            return MoodIndex.Great;
    }
}

// 5801a580dd89216bfeaa2ebc904720a7 - Just Breathe
// 8dee472c9afbcf5a9325374d3a30b570 - Resetting after mistakes
// 48879c2cbc97c2dabe85613dfa99d284 - Barely dancing?
// 9645a0c8487c7062cf2d95debacf12d1 - Safari
// 45d340a6de812d5df79ab0e2d59d4fb5 - Unwind your wrists
// 459a0c6e166d043c60b22a77b58d6d4d - Tap into gratitude

const resetMatrix = [
    ['5801a580dd89216bfeaa2ebc904720a7'],
    ['8dee472c9afbcf5a9325374d3a30b570'],
    ['48879c2cbc97c2dabe85613dfa99d284'],
    ['9645a0c8487c7062cf2d95debacf12d1'],
    ['45d340a6de812d5df79ab0e2d59d4fb5', '459a0c6e166d043c60b22a77b58d6d4d'],
]


const resets = [
    '5801a580dd89216bfeaa2ebc904720a7',
    '8dee472c9afbcf5a9325374d3a30b570',
    '48879c2cbc97c2dabe85613dfa99d284',
    '9645a0c8487c7062cf2d95debacf12d1',
    '45d340a6de812d5df79ab0e2d59d4fb5', 
    '459a0c6e166d043c60b22a77b58d6d4d',
]


const resetIdToURL = (resetId: string) => `https://customer-repfrq2uozf9ok0e.cloudflarestream.com/${resetId}/manifest/video.m3u8`

const pregameQuestions: Question[] = [
    {
        prompt: "Are you getting gud yet?",
        answers: [
            "Yeah!",
            "Yeah",
            "Yeah?",
            "Yeah...",
            "..."
        ]
    },
    {
        prompt: "Who are you playing with today?",
        answers: [
            "People I know",
            "New friends",
        ]
    },
    {
        prompt: "What would make this a good time?",
        answers: [
            "Chill out Game",
            "Action Game"
        ]
    }
]

function MainPage({ postReset, postGame, defaultIndex }: PregameInput) {
    const navigate = useNavigate()
    const [index, setIndex] = useState(defaultIndex ?? (postReset ? 3 : 1))
    const [mood, setMood] = useState<Mood>(Mood.Okay)
    const [moodScore, setMoodScore] = useState(50)
    const [QOTD, setQOTD] = useState<Question>(pregameQuestions[0])    

    const [eTest, setETest] = useState<string>()

    const nextPage = () => {
        if (index < 5) {
            setIndex(index + 1)
        }
    }

    const lastPage = () => {
        if (index > 1) {
            setIndex(index - 1)
        }
    }

    const xAxisControls = (event:any) => {
        setETest(event.key)
        if (event.key === 'd' || event.key === 'GamepadLeftThumbstickRight' || event.key === 'GamepadRightThumbstickRight') {
            if (index < 5) {
                setIndex(index + 1)
            }
        } 
        if (event.key === 'a' || event.key === 'GamepadLeftThumbstickLeft' || event.key === 'GamepadRightThumbstickLeft') {
            if (index > 1) {
                setIndex(index - 1)
            }
        } 
    }

    useEffect(() => {
        setQOTD(pregameQuestions[Math.floor(Math.random() * pregameQuestions.length)])
    }, [])

    useEffect(() => {
        const mp = document.getElementsByTagName('body')[0]
        if (!!mp) {
            mp.addEventListener('keydown', xAxisControls)
            return () => {
                mp.removeEventListener('keydown', xAxisControls)
            }
        }
    }, [index])
    
    useEffect(() => {
        const mp = document.getElementsByTagName('body')[0]
        if (!!mp) {
            mp.addEventListener('keydown', event => {
                if (event.key === 'w' || event.key === 'GamepadLeftThumbstickUp' || event.key === 'GamepadRightThumbstickUp') {
                    if (moodScore < 100) {
                        setMoodScore(moodScore + 1)
                    }
                } 
                if (event.key === 's' || event.key === 'GamepadLeftThumbstickDown' || event.key === 'GamepadRightThumbstickDown') {
                    if (moodScore > 0) {
                        setMoodScore(moodScore - 1)
                    }
                } 
            })
        }
    }, [moodScore])

    const tabClass = (id: number) => "main-tab" + (id === index ? " active-tab" : " inactive-tab")

    function MicroCheckin() {
        return (
            <div className={tabClass(4)} id="4">
            <span className="tab-title">[Group / Chal Name]</span>
                <div className='tab-content'>
                    <h3>Microstep Name</h3>
                    <p>Microstep info here lalalalalalalalaa</p>
                    <p>Group avatars / status indicators</p>
                </div>
            </div>
        )
    }

    function Learn() {
        return (
            <div className={tabClass(3)} id="3">
            <span className="tab-title">Learn</span>
                <div className='tab-content'>
                    <h3>Learn Vids Gallery here</h3>
                </div>
            </div>
        )
    }

    function PregameDone() {
        return (
            <div className='tab-content'>
                <h3>Ready to start session!</h3>
                <p>Check back in here after your done with your session</p>
                <button onClick={() => navigate('/postgame1')}>
                    done
                </button>
            </div>
        )
    }

    function PostgameDone() {
        return (
            <div className='tab-content'>
                <h3>Ready move on with your day!</h3>
            </div>
        )
    }

    function MoodSurvey() {
        return (<div style={{marginTop: "50px"}}>
            {/* <button className='survey-btn' onClick={() => SubmitMoodSurvey(4)}>Great</button>
            <button className='survey-btn' onClick={() => SubmitMoodSurvey(3)}>Good</button>
            <button className='survey-btn' onClick={() => SubmitMoodSurvey(2)}>Okay</button>
            <button className='survey-btn' onClick={() => SubmitMoodSurvey(1)}>Kinda Bad</button>
            <button className='survey-btn' onClick={() => SubmitMoodSurvey(0)}>Awful</button> */}
        </div>)
    }

    function MoodSurveyButton(btnMood: Mood) {
        return <button className='survey-btn' onClick={() => SubmitMoodSurvey()} style={{backgroundColor: mood === btnMood ? "green" : "white" }}>{btnMood}</button>
    }

    function SubmitMoodSurvey() {
        setMood(mood) // Submit
        nextPage()
    }

    function ResetModal() {

        return (<div className='modal reset-modal'>
            <HLS videoURL={resets[toMoodIndex(mood)][Math.floor(Math.random() * resets[toMoodIndex(mood)].length)]}/>
        </div>)
    }
    

    return (
        <div>
            {/* <ResetModal /> */}
            <div style={{position: "absolute", top: "0px"}}>
                <div className={tabClass(1)} id="1">
                    <span className="tab-title">How are you doing?</span>
                    <div className='tab-content'>
                        <MoodSurvey />
                    </div>
                </div>

                <div className={tabClass(2)} id="2">
                    <span className="tab-title">Question of the day</span>
                    <div className='tab-content'>
                        <h5>{QOTD.prompt}</h5>
                        {QOTD.answers.map((ans, i) => <button className='survey-btn' key={i} onClick={() => nextPage()}>{ans}</button>)}
                    </div>
                </div>

                <div className={tabClass(3)} id="3">
                    <span className="tab-title">Reset</span>
                    <div className='tab-content'>
                        {/* <HLS videoURL={resetIdToURL(resetMatrix[toMoodIndex(mood)][Math.floor(Math.random() * resetMatrix[toMoodIndex(mood)].length)])}/> */}
                        {/* <HLS videoURL={resetIdToURL(resets[Math.floor(Math.random() * resets.length)])} /> */}
                        {/* <button onClick={() => navigate(`/${postGame ? 'postgame' : 'pregame'}v`)}>
                            Go
                        </button> */}
                    </div>
                </div>

                {//id="4"
                }
                {postGame ? <Learn /> : <MicroCheckin />}

                <div className={tabClass(5)} id="5">
                    <span className="tab-title">Done</span>
                    {postGame ? <PostgameDone /> : <PregameDone />}
                </div>

                {/* <script>
                    const newFocusRoot = document.getElementById({index.toString()});
                    TVJS.DirectionalNavigation.focusRoot = newFocusRoot;
                </script> */}
            </div>
        </div>
    )
}

function Smile() {
    const [smileFactor, setSmileFactor] = useState(50)
    
    const smileUp = () => {
        if (smileFactor <= 100)  {
            setSmileFactor(smileFactor + 25)
        }
    }

    const smileDown = () => {
        if (smileFactor > -100)  {
            setSmileFactor(smileFactor - 25)
        }
    }

    useEffect(() => {
        const mp = document.getElementsByTagName('body')[0]
        if (!!mp) {
            mp.addEventListener('keydown', event => {
                console.log(event)
                if (event.key === 'w') {
                    smileUp()
                } 
                if (event.key === 's') {
                    smileDown()
                } 
            })
        }
    }, [smileFactor])

    // const mood = (() => {
    //     if (smileFactor >= 150) {
    //         return "Great"
    //     }
    // })()

    return (
        <div>
            <h3>{(smileFactor + 100) / 20}</h3>
            <div style={{
            width: "1000px",
            height: "200px",
            margin: "auto",
            border: "solid 5px #fff",
            borderColor: "#fff transparent transparent transparent",
            borderRadius: `${Math.abs(smileFactor)}%/300px 300px 0 0`,
            transform: smileFactor > 0 ? "rotate(180deg)" : ""
            }}></div>
        </div>
    )
}

export default MainPage;