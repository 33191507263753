import { useState } from "react"
import VideoPage from "./VideoPage"

function IntroPage() {
    const [ready, setReady] = useState(false)

    if (ready) {
        return <VideoPage videoURL="https://customer-repfrq2uozf9ok0e.cloudflarestream.com/48879c2cbc97c2dabe85613dfa99d284/manifest/video.m3u8" />
    } else {
        return (
            <div>
                <h1>Welcome to Thrive</h1>
                <button onClick={() => setReady(true)}>
                    Begin
                </button>
            </div>
        )
    }
}

export default IntroPage