import { useState } from 'react'
import ReactPlayer from 'react-player'

function HLS({ videoURL }) {
    const [isPlaying, setIsPlaying] = useState(false)
    return (<div>
        <ReactPlayer url={videoURL} playing={isPlaying} style={{margin: "auto"}}/>
        <button onClick={() => setIsPlaying(!isPlaying)}>{isPlaying ? "Pause" : "Play"}</button>
    </div>)
}

export default HLS