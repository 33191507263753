import { useState } from "react";
import CaptiveVideo from "../components/CaptiveVideo";
import { Survey, SurveyResponse } from "../types";
import Player from "../video/Player";

function SurveyPage({ questions, resets, postGame }: Survey) {
    const [qIndex, setQIndex] = useState(0)
    const [responses, setResponses] = useState<SurveyResponse[]>([])

    const recordResponse = (answer: string) => {
        const resp: SurveyResponse = {
            prompt: questions[qIndex].prompt,
            answer,
        }

        setResponses([resp, ...responses])
        setQIndex(qIndex + 1)
    }

    const bgColor = (answer: string) => {
        switch (answer) {
            case "I want to release tension":
                return "#D4E1C5"
            case "I need to focus my mind":
                return "#A8EEFF"
            case "I'd like to wind down":
                return "#AEA8FF"
            default:
                return "white"
        }
    }

    if (questions[qIndex]) {
        return (
            <div>
                <p id="surveyPrompt">{questions[qIndex].prompt}</p>
                <div id="surveyAnswers">
                    {questions[qIndex].answers.map(answer => <button style={{backgroundColor: bgColor(answer)}} className="survey-btn" onClick={() => recordResponse(answer)}>{answer}</button>)}
                </div>
                {/* <script>
                    const newFocusRoot = document.getElementById("surveyAnswers");
                    TVJS.DirectionalNavigation.focusRoot = newFocusRoot;
                </script> */}
            </div>
        )
    } else {
        if (!!resets) {
            let resetURL;
            switch (responses[responses.length - 1].answer) {
                case "I want to release tension":
                    resetURL = resets[0]
                    break
                case "I need to focus my mind":
                    resetURL = resets[1]
                    break
                case "I'd like to wind down":
                    resetURL = resets[2]
                    break
                default:
                    resetURL = resets[0]
            }
            return <Player videoURL={resetURL} flowNav={postGame ? '/postgame2' : "/pregame2"} />
        } else {
            return (
                <h1> Survey Complete </h1>
            )
        }
    }

}

export default SurveyPage
