import { useNavigate } from "react-router-dom"


function ChallengesPage() {
    const navigate = useNavigate()
    const challanges = ["chal1", "chal2", "chal3", "chal4", "chal5"]
    const recordResponse = (resp: string) => {
        navigate('/challenge')
    }

    return (
        <div>
            <h1>which challenge</h1>
            {
                challanges.map((chal) => 
                    <button className="survey-btn" onClick={() => recordResponse(chal)}>{chal}</button>
            )}
        </div>
    )
}

export default ChallengesPage