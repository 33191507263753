import { useNavigate } from "react-router-dom"

function MicrostepPage() {
    const nav = useNavigate()

    const microstepDone = () => {
        /*
            In the future we may want to load into any microsteps a user has
            immediatly on boot. so this will handle
        */
        nav('/done')
    }

    return (
        <div>
            <p style={{textAlign: "left", marginLeft: "10px"}}>maybe challange name</p>
            <h1>microstep copy lorem ipsum dolor sit amet </h1>
            <button onClick={microstepDone}>done</button>
        </div>
    )
}

export default MicrostepPage