import { useNavigate } from "react-router-dom"

function ChallengePage() {
    const nav = useNavigate()
    return (
        <div>
            <h1>challenge name</h1>
            <p>some stuff about this challenge</p>
            <button onClick={() => nav('/microstep')}>Join Solo</button>
            <button onClick={() => nav('/groups')} style={{marginLeft: "10px"}}>Find Group</button>
        </div>
    )
}

export default ChallengePage