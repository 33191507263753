import { useNavigate } from "react-router-dom"

function ChallengeGroupsPage() {
    const nav = useNavigate()
    const groups = [
        "Thrivers",
        "Wellness Patrol",
        "Challenge Squad"
    ]
    return (
        <div>
            <h1>Find a group</h1>
            {
                groups.map((chal) => 
                    <button className="survey-btn" onClick={() => {
                        // Maybe we do a group profile page here. For now just back to microstep like solo
                        nav('/microstep')
                        return true
                    }}>{chal}</button>
            )}
        </div>
    )
}

export default ChallengeGroupsPage