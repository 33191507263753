import { useEffect, useState } from "react";

interface Tab {
    title: string,
    // type: TabType,
}

enum TabType {
    DayCheck = "day check",
    Reset = "reset",
}

function ClosedTab(tab: Tab, isPast: boolean) {
    return (
        <div className="closed-tab" style={{float: isPast ? "right" : "left"}}>
            <span>{tab.title}</span>
        </div>
    )
}

function OpenTab(tab: Tab) {
    <div className="open-tab">
            <h1>{tab.title}</h1>
    </div>
}

function MainPage() {
    const [past, setPast] = useState<Tab[]>([{ title: "Tab1" },{ title: "Tab2"},{ title: "Tab3"}])
    const [present, setPresent] = useState<Tab>({ title: "Tab4"})
    const [future, setFuture] = useState<Tab[]>([{ title: "Tab5"},{ title: "Tab6"},{ title: "Tab7"}])
    // const [tabs, setTabs] = useState<Tab[]>([{ title: "Tab1"},{ title: "Tab2"},{ title: "Tab3"},{ title: "Tab4"},{ title: "Tab5"}])
    // const [index, setIndex] = useState(0)


    const nextPage = () => {
        // if (index < tabs.length - 1) {
        //     setIndex(index + 1)
        // }
        if (future.length > 0) {
            setPast([...past, present])
            setPresent(future[0])
            setFuture(future.slice(1))
        }
    }

    const lastPage = () => {
        // if (index > 0) {
        //     setIndex(index - 1)
        // }
        if (past.length > 0) {
            setFuture([present, ...future])
            setPresent(past[past.length - 1])
            setPast(past.slice(0, past.length - 1))
        }
    }

    useEffect(() => {
        const mp = document.getElementsByTagName('body')[0]
        if (!!mp) {
            mp.addEventListener('keydown', event => {
                console.log(event)
                if (event.key === 's') {
                    nextPage()
                } 
                if (event.key === 'a') {
                    lastPage()
                } 
            })
        }
    }, [present])

    return (
        <div id='main-page'>
            {/* <div style={{float: "left"}}>
                {tabs.map((tab, i) => {
                    if (i < index) {
                        return ClosedTab(tab, false)
                    }
                    
                    if (i > index) {
                        return ClosedTab(tab, true)
                    }

                    if (i === index) {
                        return (
                            <div>
                                <h1>{tab.title}</h1>
                            </div>
                        )
                    }
                    
                })}
            </div> */}
            <div style={{float: "left"}}>
                {past.map(tab => ClosedTab(tab, true))}
            </div>
            <h1>{present.title}</h1>
            <div style={{float: "right"}}>
                {future.map(tab => ClosedTab(tab, false))}
            </div>
        </div>
    )

}

export default MainPage;