import { useState, useRef, useEffect, } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import CaptiveVideo from './components/CaptiveVideo';
import IntroPage from './pages/IntroPage';
import SurveyPage from './pages/SurveyPage';
import VideoPage from './pages/VideoPage';
import ReactAudioPlayer from 'react-audio-player';
import ChallengesPage from './pages/ChallengesPage';
import ChallengePage from './pages/ChallengePage';
import MicrostepPage from './pages/MicrostepPage';
import AllDonePage from './pages/AllDonePage';
import ChallengeGroupsPage from './pages/ChallengeGroupsPage';
import DeprecatedMainPage from './pages/MainPage.Deprecated';
import MainPage from './pages/MainPage';

function App() {
  const [debugTxt, setDebugTxt] = useState("")
  
  const testResets: string[] = [
    "https://assets.thriveusercontent.com/xbox/release_tension.mp4",
    "https://assets.thriveusercontent.com/xbox/focus_mind.mp4",
    "https://assets.thriveusercontent.com/xbox/wind_down.mp4",
  ]

  const testQuestions = [
    { prompt: "What brings you here today?",
      answers: [
        "I want to release tension",
        "I need to focus my mind",
        "I'd like to wind down",
      ]
    },
    // { prompt: "abcd",
    //   answers: [
    //     "a",
    //     "b",
    //     "c",
    //     "d"
    //   ]
    // },
    // { prompt: "abcd",
    //   answers: [
    //     "a",
    //     "b",
    //     "c",
    //     "d"
    //   ]
    // },
  ]

  // const handleGamepad = e => {
  //   setDebugTxt(debugTxt + e.keyCode)
  //   switch (e.keyCode) {
  //     case 195:
  //       // playerRef.current.pause()
  //       break;
  //     default:
  //       console.log("ppop")
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("keydown", handleGamepad)
  // })

  return (
    <div className="App">
      {/* <div>
        <h4>debug panel</h4>
        <span>{debugTxt}</span>
      </div> */}
      <ReactAudioPlayer src='out.mp3' autoPlay={true} />
      <BrowserRouter>
        <Routes>
          <Route path='pregame1' element={<MainPage />} />
          <Route path='pregamev' element={<SurveyPage questions={testQuestions} resets={testResets} />} />
          <Route path='pregame2' element={<MainPage postReset/>} />


          <Route path='postgame1' element={<MainPage postGame defaultIndex={1} />} />
          <Route path='postgamev' element={<SurveyPage questions={testQuestions} resets={testResets} postGame />} />
          <Route path='postgame2' element={<MainPage postGame postReset/>} />


          <Route path='oldmain' element={<DeprecatedMainPage />} />
          <Route path='intro' element={<IntroPage />}/>
          <Route path='survey' element={<SurveyPage questions={testQuestions} resets={testResets} />} />
          <Route path='challenges' element={<ChallengesPage />} />
          <Route path='challenge' element={<ChallengePage />} />
          <Route path='microstep' element={<MicrostepPage />} />
          <Route path='done' element={<AllDonePage />} />
          <Route path='groups' element={<ChallengeGroupsPage />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
