import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AllDonePage from "../pages/AllDonePage"
import { VideoArgs } from "../types"

function Player({ videoURL, flowNav }: VideoArgs) {
    const nav = useNavigate()
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [done, setDone] = useState(false)
    const [feedback, setFeedback] = useState(false)

    useEffect(() => {
        setHeight(window.innerHeight)
        setWidth(window.innerWidth)

        const video = document.querySelector('video');

        if (!!video) {
            video.addEventListener('ended', (event) => {
                setDone(true)
            });
        }
    })

    if (!done) {
        return (
            <video autoPlay height={height} width={width}>
                <source src={videoURL} type="video/mp4"></source>
            </video>
        )
    } else {
        if (!feedback) {
            return (
                <div>
                    <p id="surveyPrompt">Did that help?</p>
                    <button className="survey-btn" style={{backgroundColor: "#94d356"}} onClick={() => setFeedback(true)}>Yes, show more like this next time</button>
                    <button className="survey-btn" style={{backgroundColor: "#d3c256"}}  onClick={() => setFeedback(true)}>No, I’d like something else next time</button>
                </div>
            )
        } else {
            if (flowNav) {
                nav(flowNav)
            }
            return <AllDonePage />
        }
    }
}

export default Player